<template>
	<div class="pge">
		<myfooter @updateData="getdata" />
		<div class="mains">
			<div class="maincoin">
				<el-icon color="#fff">
					<Star />
				</el-icon>
				<el-statistic :value="outputValue" />
			</div>
			<el-image :class="'dogeimg ' + fliphorizontal" :src="imageSrc" fit="contain"></el-image>

			<el-countdown v-if="disabled" format="HH:mm:ss" @finish="Countdown(collectiontime)" :value="deadline">
				<template #title>
					<div style="display: flex; flex: 1; color: #fff; justify-content: center">
						<span> Next claim: </span>
					</div>
				</template>
			</el-countdown>
			<el-button v-else type="primary" @click="updatintegral" :loading="loading"> Get today's reward </el-button>
			<!-- <el-statistic :value="deadline" time-indices> </el-statistic> -->
		</div>
		<MYnav />
	</div>
</template>

<script>
	import store from './../store'
	import { axiosInstance } from '@/api/post'
	import { ref, onMounted } from 'vue'
	import { mapState } from 'vuex'
	import { useTransition } from '@vueuse/core'
	import { modal } from '@/api/web3ModalConfig'
	import myfooter from '@/components/myfooter'
	import MYnav from '@/components/nav'
	import { BrowserProvider, Contract, formatUnits } from 'ethers'
	// import mycar from '@/components/car'
	export default {
		computed: {
			...mapState(['id', 'integral', 'Teamrewards', 'collectiontime']),
		},
		components: {
			myfooter,
			MYnav,
		},

		data() {
			return {
				outputValue: 0,
				fliphorizontal: '',
				imageSrc: require('../assets/doge.png'),
				deadline: 0,
				disabled: true,
				loading: false,
			}
		},
		methods: {
			//

			getdata() {
				try {
					// 这里要计算
					if (this.collectiontime > 0) {
						this.Countdown(this.collectiontime)
					}

					this.updateSourceValue(0, Number(this.integral) + Number(this.Teamrewards))
				} catch (error) {
					console.log(error)
				}
			},

			async updatintegral() {
				try {
					this.loading = true
					// this.collectiontime  加上24小时的毫秒倒计时的截止时间
					this.fliphorizontal = 'zoom-in'
					window.Telegram.WebApp.HapticFeedback.impactOccurred('medium')
					setTimeout(() => {
						this.fliphorizontal = '' // 动画结束后恢复原始状态
					}, 200)
					// 上传到后端
					const user = await axiosInstance.post('/Update/integral', this.id)
					if (user.data.code == 200) {
						this.updateSourceValue(Number(this.integral), Number(user.data.data.integral) + Number(this.Teamrewards))
						store.commit('setIntegral', Number(user.data.data.integral))
						store.commit('setCollectiontime', user.data.data.Collectiontime)

						if (this.collectiontime > 0) {
							this.Countdown(this.collectiontime)
						}
						this.$message({
							message: 'Received successfully',
							type: 'success',
						})
					} else {
						this.$message.error('Not yet time')
					}
					this.loading = false
				} catch (error) {
					this.loading = false
					console.log(console)
				}
			},
			updateSourceValue(newValue, newnum) {
				try {
					let source = ref(newValue)
					this.outputValue = useTransition(source, {
						duration: 500,
					})
					source.value = newnum
				} catch (error) {
					console.log(error)
				}
			},
			// 计算倒计时

			Countdown(time) {
				this.deadline = Number(time) + 1000 * 60 * 60 * 12
				if (Date.now() > this.deadline) {
					// 倒计时结束
					this.disabled = false
				} else {
					// 倒计时中
					this.disabled = true
				}

				// 判断是否倒计时 time 是领取的时间
				// 如果真就显示倒计时 如果否 就显示领奖按钮
			},
		},
		mounted() {
			//
		},
		beforeCreate() {
			// clearInterval(this.pollInterval)
		},
	}
</script>
<style scoped>
	.mains {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center; /* 垂直居中 */
		margin: 3vh 0;
	}
	.maincoin {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		gap: 0.1rem;
		font-weight: bold;
		font-size: 6vh;
		color: #fff;
	}
</style>
<style>
	.pge {
		height: var(--tg-viewport-stable-height);
		display: flex;
		flex-direction: column;
	}
	.dogeimg {
		height: 35vh;
	}
	.zoom-in {
		transform: scale(1.02); /* 放大1.5倍，可根据需求调整 */
		transition: transform 0.2s ease;
	}
	.el-statistic__content {
		color: #fff !important;
		font-size: 6vh !important;
		font-weight: bold !important;
	}
	.mains .el-statistic__head {
		color: #fff;
		font-size: 3vh;
		font-weight: bold;
	}
</style>
