<template>
	<div v-loading.fullscreen="fullscreenLoading" element-loading-background="#6a4c4185" class="footer">
		<div class="main">
			<div>{{ name }}</div>
			<!-- <w3m-button class="button" size="sm" /> -->
		</div>
	</div>
</template>

<script>
	import store from './../store'
	import { axiosInstance } from '@/api/post'
	import { modal } from '@/api/web3ModalConfig'
	import { useWeb3ModalAccount } from '@web3modal/ethers/vue'
	import { ethers } from 'ethers'

	import eruda from 'eruda'
	export default {
		name: 'MyFooter',
		components: {},
		data() {
			return {
				telegramInitData: null,
				name: null,
				fullscreenLoading: false,
			}
		},
		methods: {
			async user() {
				try {
					// 打开调试
					// eruda.init()

					this.fullscreenLoading = true
					let urlEncodedString = window.Telegram.WebApp.initData
					window.Telegram.WebApp.setHeaderColor('#6a4c41')
					window.Telegram.WebApp.setBackgroundColor('#6a4c41')
					const decodedString = decodeURIComponent(urlEncodedString)
					// 提取 JSON 部分
					const jsonString = decodedString.split('&')[0].replace('user=', '')

					// 将 JSON 字符串转换为 JavaScript 对象
					const userObject = JSON.parse(jsonString)

					// 提取其他参数
					const params = new URLSearchParams(decodedString)
					const chatInstance = params.get('chat_instance')
					const chatType = params.get('chat_type')
					const authDate = params.get('auth_date')
					const hash = params.get('hash')
					const start = params.get('start_param')
					// 组装成目标格式
					this.telegramInitData = {
						user: userObject,
						chat_instance: parseInt(chatInstance, 10),
						chat_type: chatType,
						auth_date: parseInt(authDate, 10),
						hash: hash,
						start: start,
					}
					this.name = this.telegramInitData.user.first_name + this.telegramInitData.user.last_name
					this.name = this.name.length > 12 ? this.name.substring(0, 12) + '...' : this.name
					const response = await axiosInstance.post('/Create/register', this.telegramInitData)
					store.commit('setId', this.telegramInitData.user.id)
					const user = await axiosInstance.post('/Query/user', this.telegramInitData.user.id)
					console.log(user)
					if (user.data.code == 200) {
						store.commit('setIntegral', user.data.data.integral)
						store.commit('setUserid', user.data.data.id)
						store.commit('setTeamrewards', Number(user.data.data.Teamrewards))
						store.commit('setCollectiontime', user.data.data.Collectiontime)
						store.commit('setCount', user.data.data.count)

						this.$emit('updateData')
					}
					// 获取其他信息
					this.fullscreenLoading = false
					window.Telegram.WebApp.ready()

					// 注册
				} catch (error) {
					console.log(error)
				}
			},
			async getinfo() {
				const { address, chainId, isConnected } = useWeb3ModalAccount()
				console.log(isConnected)
				// console.log(address)
				const USDTAddress = '0x617f3112bf5397D0467D315cC709EF968D9ba546'

				// The ERC-20 Contract ABI, which is a common contract interface
				// for tokens (this is the Human-Readable ABI format)
				const USDTAbi = ['function name() view returns (string)', 'function symbol() view returns (string)', 'function balanceOf(address) view returns (uint)', 'function transfer(address to, uint amount)', 'event Transfer(address indexed from, address indexed to, uint amount)']

				const walletProvider = modal.getWalletProvider()
				// console.log(walletProvider)
				const ethersProvider = new ethers.BrowserProvider(walletProvider)
				// console.log(ethersProvider)
				const signer = await ethersProvider.getSigner()
				// The Contract object

				// console.log(signer)
				// const USDTContract = new Contract(USDTAddress, USDTAbi, signer)
				// const USDTBalance = await USDTContract.balanceOf(address)

				// console.log(formatUnits(USDTBalance, 18))
			},
		},
		mounted() {
			//
			this.user()
		},
	}
</script>
<style scoped>
	.footer {
		display: flex;
		width: 100vw;
		height: 10vh;
		align-items: center; /* 垂直居中 */

		/* opacity: 0.3; */
	}
	.footer .main {
		width: 90vw;
		color: aliceblue;
		margin: auto;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between; /* 左右两边分布 */
		align-items: center; /* 上下居中 */
	}
	/* .button button[data-size='sm'] {
		background: floralwhite !important;
	} */
</style>
<style>
	wui-flex > wui-text {
		color: aliceblue;
	}
	.el-loading-spinner .path {
		stroke: #6a4c41 !important;
	}
</style>
