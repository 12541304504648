<template>
	<div class="nav">
		<div class="list">
			<router-link class="box" to="/">
				<el-icon><HomeFilled /></el-icon>
				<span>Home</span>
			</router-link>
			<router-link class="box" to="/ref">
				<el-icon><HelpFilled /></el-icon>
				<span>Ref</span>
			</router-link>
			<router-link class="box" to="/StatsView">
				<el-icon><Histogram /></el-icon>
				<span>Stats</span>
			</router-link>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'MYnav',
		components: {},
		data() {
			return {
				defaultchanid: '0',
			}
		},
		methods: {
			//
		},
		mounted() {
			//
		},
	}
</script>
<style scoped>
	.nav {
		width: 100vw;
		border-radius: 0px 0px 0px 0px;
		/* opacity: 0.3; */
	}
	.nav .list {
		width: 90vw;
		height: 16vh;
		color: aliceblue;
		margin: auto;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-evenly;
		align-items: center; /* 垂直居中 */
	}
	.nav .box {
		display: flex;
		width: 12vh;
		height: 12vh;
		border-radius: 0.08rem;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0.5vh;
		font-size: 3vh;
		font-weight: bold;
		background: rgba(22, 27, 40, 0.1);
	}
	.router-link-active {
		background: rgba(22, 27, 40, 0.3) !important;
	}
</style>
