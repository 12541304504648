// src/web3ModalConfig.ts
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/vue';
// import { mainnet, arbitrum } from 'viem/chains'

// 1. Get projectId from https://cloud.walletconnect.com
const projectId = '5f931bf56115df8e871c07ad48050535';

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://mainnet.infura.io/v3/',
};
const Polygon = {
  chainId: 137,
  name: 'Polygon',
  currency: 'MATIC',
  explorerUrl: 'https://polygonscan.com',
  rpcUrl: 'https://polygon.drpc.org',
};


// 3. Create your application's metadata object
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com', // url must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/'],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: false,
  defaultChainId: 137, // Coinbase SDK Default Chain ID
  auth: {
    email: false, // default to true
  }
});

// 5. Create a Web3Modal instance
const modal = createWeb3Modal({
  ethersConfig,
  chains: [mainnet, Polygon],
  allowUnsupportedChain: false,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
  featuredWalletIds: [//主要试图
    // '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
    'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b'],
  includeWalletIds: [
    // 'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b', //扫码
    // '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66',//TP钱包
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', //小狐狸
    '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662' //BIT
  ],
  // excludeWalletIds: [
  //   'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b',//PAICHU
  //   'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa'
  // ]

});

export { modal };
